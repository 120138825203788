import Swal from "sweetalert2";
import axios from "./services/axios";

const AxiosInterceptorsSetup = (navigate, location) => {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        // Save the current location to sessionStorage
        const currentPath = `${location.pathname}${location.search}`;
        console.log("current path was: ", currentPath);
        sessionStorage.setItem("redirectAfterSignUp", currentPath);

        // Notify the user and redirect to login
        Swal.fire({
          title: "User Session has been Expired!",
          timer: 2000,
          icon: "error",
          showConfirmButton: false,
        });

        setTimeout(() => {
          localStorage.removeItem("sparrowSignIn");
          navigate("/signin");
        }, 2000);
      }
      return Promise.reject(error);
    }
  );
};

export default AxiosInterceptorsSetup;
