import React, { useContext, useEffect, useState } from "react";

import { Controller, useForm } from "react-hook-form";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import { SearchStudent } from "../../services/users/SearchStudent";
import Swal from "sweetalert2";
import AsyncSelect from "react-select/async";
import AuthContext from "../../context/AuthContext";
import { getLocations100Random } from "../../services/locations/Locations";
import CreatableSelect from "react-select/creatable";
import { isMobile } from "react-device-detect";

function HomeBanner() {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  let navigate = useNavigate();
  const auth = useContext(AuthContext);
  const [randomLocation, setRandomLocation] = useState([]);

  const GetlocationsRamdomFunc = async () => {
    const optionlocation = [];
    const resp = await getLocations100Random();
    resp?.data?.map((singleLocation) => {
      return optionlocation.push({
        label: singleLocation.city + " " + singleLocation.state + "," + singleLocation.zip,
        value: singleLocation.id,
      });
    });
    setRandomLocation(optionlocation);
  };

  useEffect(() => {
    GetlocationsRamdomFunc();
  }, []);

  // option for task to make it option
  const optionTaskValue = [
    { value: "Child Care", label: "Child Care" },
    { value: "Tutoring", label: "Tutoring" },
    { value: "Coaching", label: "Sports Coaching" },
    { value: "Yard Work", label: "Yard Work" },
    { value: "Moving", label: "Moving" },
    { value: "Grocery Delivery", label: "Grocery Delivery" },
    { value: "Housekeeping", label: "Housekeeping" },
    { value: "Pet Sitting", label: "Pet Sitting" },
    { value: "Elder Care", label: "Elder Care" },
    { value: "Window Cleaning", label: "Window Cleaning" },
    // {
    //   label: "Tutoring",
    //   value: "Tutoring",
    // options: [
    //   { value: "English", label: "English", parent: "Tutoring" },
    //   { value: "Math", label: "Math", parent: "Tutoring" },
    //   { value: "Chemistry", label: "Chemistry", parent: "Tutoring" },
    //   { value: "History", label: "History", parent: "Tutoring" },
    //   {
    //     value: "Religious Education",
    //     label: "Religious Education",
    //     parent: "Tutoring",
    //   },
    //   { value: "Geography", label: "Geography", parent: "Tutoring" },
    // ],
    // },
    // {
    //   label: "Sports Coaching",
    //   value: "Coaching",
    // options: [
    //   { value: "Baseball", label: "Baseball", parent: "Sports Coaching" },
    //   { value: "Soccer", label: "Soccer", parent: "Sports Coaching" },
    //   { value: "Ice Hockey", label: "Ice Hockey", parent: "Sports Coaching" },
    //   { value: "Tennis", label: "Tennis", parent: "Sports Coaching" },
    //   { value: "Golf", label: "Golf", parent: "Sports Coaching" },
    //   { value: "Boxing", label: "Boxing", parent: "Sports Coaching" },
    //   {
    //     value: "American Football",
    //     label: "American Football",
    //     parent: "Sports Coaching",
    //   },
    // ],
    // },
  ];

  const filterLocationOption = (inputValue) => {
    if (inputValue.length > 1) {
      return auth.locations?.filter((i) => i?.label?.toLowerCase().includes(inputValue.toLowerCase()));
    } else {
      let result = auth.locations?.filter((i) => i?.label?.toLowerCase().includes(inputValue.toLowerCase()));
      return result.splice(0, 80);
    }
  };

  const loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(filterLocationOption(inputValue));
    }, 1000);
  };

  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled ? "#cacaca" : isSelected ? "#82D051" : isFocused ? undefined : undefined,
        color: isDisabled ? "#cacaca" : isSelected ? "#fcfcfc" : isFocused ? "#898A8D" : "#898A8D",
        cursor: isDisabled ? "not-allowed" : "default",
        ":active": {
          ...styles[":active"],
          backgroundColor: "#82D051",
        },
      };
    },
    input: (styles) => ({
      ...styles,
      color: "#898A8D",
      fontFamily: "'Manrope', sans-serif",
      fontWeight: 400,
    }),
    placeholder: (styles) => ({
      ...styles,
      color: "#898a8d",
      fontFamily: "'Manrope', sans-serif",
      fontWeight: 400,
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "#898A8D",
      fontFamily: "'Manrope', sans-serif",
      fontWeight: 400,
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: "999",
    }),
  };

  const onSubmit = async (fData) => {
    let dataObj = {};

    if (fData.location && fData.location.label) {
      let arrayForCityZip = fData.location.label.split(",");
      // dataObj["city"] = arrayForCityZip[0]
      dataObj["zip"] = parseInt(arrayForCityZip[1]);
    }

    // dataObj["skills"] = fData.taskInputValue.value;

    const resp = await SearchStudent(dataObj);
    // console.log(dataObj);
    // console.log(resp);

    if (resp.status === 200) {
      if (resp?.data?.users.length == 0) {
        Swal.fire({
          title: "No user Found.",
          timer: 1500,
          icon: "warning",
          showConfirmButton: false,
        });
      } else {
        var dataToStore = {
          pageName: "homePage",
          location: dataObj.zip,
        };

        // Storing the object in sessionStorage
        sessionStorage.setItem("pageSearch", JSON.stringify(dataToStore));
        setTimeout(() => {
          // sessionStorage.setItem("pageSearch", JSON.stringify(fData?.location));
          // debugger;
          navigate("/signInRoute/all-students", {
            state: { query: resp?.data?.users, defaultValue: fData },
          });
        }, 1200);
      }
    } else {
      Swal.fire({
        title: resp.data.message,
        timer: 1500,
        icon: "error",
        showConfirmButton: false,
      });
    }
  };
  const isiPhone = window.navigator.platform === "iPhone";

  return (
    <div className="banner center bannerMain logInClass">
      <Container>
        <Row className="columnRevers">
          <Col md={7} sm={12} className="paddingLft20 mainbannerTextPart">
            <h2>
              Hire & Support <span className="green">Local Students</span> to Manage Your <span id="dailyForMobile">Daily</span> Tasks
            </h2>
            <div className="seth6width">
              <h6>Sign up to instantly connect with thousands of local students ready to meet your needs at home or for your business.</h6>
            </div>
            <div className="bannerFields">
              <form id="homeSearchForm" className="bannerFields row" onSubmit={handleSubmit(onSubmit)}>
                <div className="flex1 col-md-6 col-sm-12 locationSectionHome forDesktop">
                  <Controller
                    name="select"
                    className="border-0"
                    control={control}
                    {...register("location", { required: true })}
                    render={({ field }) => (
                      <AsyncSelect
                        {...field}
                        className="cityField homePageCityField border-mb-fields border-0"
                        cacheOptions
                        loadOptions={loadOptions}
                        // defaultOptions={auth.locations?.slice(0, 50)}
                        styles={colourStyles}
                        defaultOptions={randomLocation}
                        placeholder="City or zip code"
                      />
                    )}
                  />
                  {errors.location && <span className="eror locationError">This field is required</span>}
                </div>
                <div className="flex2 col-md-6 col-sm-12 locationSectionHome forMobile customSearchField">
                  <Controller
                    name="select"
                    className="border-0"
                    control={control}
                    {...register("location", { required: true })}
                    render={({ field }) => (
                      <AsyncSelect
                        {...field}
                        className="cityField homePageCityField border-mb-fields border-0"
                        cacheOptions
                        loadOptions={loadOptions}
                        // defaultOptions={auth.locations?.slice(0, 50)}
                        styles={colourStyles}
                        defaultOptions={randomLocation}
                        placeholder="City or zip code"
                      />
                    )}
                  />
                  <button className={`taskbutton primary center ${isMobile && "customMobileHomeBannerButton"}`}>Find Student</button>
                  {errors.location && <span className="eror locationError">This field is required</span>}
                </div>
                <div className="customSearchField flex2 col-md-6 col-sm-12">
                  {/* <Controller
                    name="select"
                    className="border-0 "
                    control={control}
                    {...register("taskInputValue")}
                    render={({ field }) => (
                      <CreatableSelect
                        {...field}
                        styles={colourStyles}
                        className="findTasksFields border-0 border-mb-fields"
                        isClearable
                        options={optionTaskValue}
                        placeholder="Task"
                      />
                    )}
                  /> */}
                  <button className="taskbutton taskbuttonleft primary center">Find Student</button>
                  {/* {errors.taskInputValue && (
                    <span className="eror">This field is required</span>
                  )} */}
                </div>
              </form>
            </div>

            <div className="bannerLogos setResonive">
              <img src="/assets/images/home/Trusted by.svg" alt="" />
              <img src="/assets/images/home/SanMateo.png" alt="" />
              <img src="/assets/images/home/la.svg" alt="" />
              <img src="/assets/images/home/Sanjose.png" alt="" />
            </div>

            <div className="makeMobileOnly setResonive1 trust_mobileImage">
              <img src="/assets/images/home/Trusted by.svg" alt="" />
            </div>
            <div className="makeMobileOnly setResonive1 bannerLogos_mobile">
              {isiPhone ? (
                <>
                  <img src="/assets/images/home/SanMateo.png" alt="" />
                  <img src="/assets/images/home/la.svg" alt="" />
                  <img src="/assets/images/home/Sanjose.png" alt="" />
                </>
              ) : (
                <>
                  <img src="/assets/images/home/SanMateo.png" alt="" />
                  <img src="/assets/images/home/la.svg" alt="" />
                  <img src="/assets/images/home/Sanjose.png" alt="" />
                </>
              )}
            </div>
          </Col>
          <Col md={5} sm={12} className="mainbannerImagePart">
            <div className="banerkiImgDiv">
              <img className="mainHomeBannerImage" src="/assets/images/home/bannerImg.png" alt="" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default HomeBanner;
