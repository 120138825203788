import React, { useEffect, useState } from "react";
import { Col, Container, Row, Pagination } from "react-bootstrap";
import AdvacnceSearchBar from "../advancesearchbar/AdvacnceSearchBar";
import StudentProfileCard from "../Home/StudentProfileCard";
import Swal from "sweetalert2";
import Loader from "../loader/Loader";
import { useSearchParams } from "react-router-dom";
import { ShowAllStudentsForSearch } from "../../services/users/ShowAllStudentsForSearch";

const ViewAllStudents = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [loader, setLoader] = useState(true);
  const [displayedStudents, setDisplayedStudents] = useState([]);
  const [searchedValue, setSearchedValue] = useState();
  const [totalPages, setTotalPages] = useState(1);
  const [seed, setSeed] = useState(null);
  const bookNow = "Connect";

  const currentPage = parseInt(searchParams.get("page")) || 1;

  useEffect(() => {
    const queryParams = { ...Object.fromEntries([...searchParams]) };

    // Determine if filters are applied
    const filterParams = { ...queryParams };
    delete filterParams.page; // Exclude 'page' from filters

    const filtersApplied = Object.keys(filterParams).length > 0;

    if (filtersApplied) {
      // Filters are applied, clear localStorage and fetch new data
      localStorage.removeItem("studentsData");
      getAllStudentFunc(queryParams);
    } else {
      // No filters applied, proceed with caching logic
      const savedData = localStorage.getItem("studentsData");

      if (savedData) {
        const { students, queryParams: savedQueryParams, seed: savedSeed, totalPages: savedTotalPages } = JSON.parse(savedData);
        const paramsAreEqual = JSON.stringify(savedQueryParams) === JSON.stringify(queryParams);

        if (paramsAreEqual) {
          // Use cached data
          setDisplayedStudents(students);
          setSeed(savedSeed);
          setTotalPages(savedTotalPages);
          setLoader(false);
        } else {
          // Fetch new data
          getAllStudentFunc(queryParams);
        }
      } else {
        // Fetch new data
        getAllStudentFunc(queryParams);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    // Handler to remove studentsData from localStorage
    const handlePageHide = () => {
      localStorage.removeItem("studentsData"); // Remove on page hide
    };

    window.addEventListener("pagehide", handlePageHide);

    // Cleanup event listener when component unmounts
    return () => {
      window.removeEventListener("pagehide", handlePageHide);
    };
  }, []);

  const getAllStudentFunc = async (queryParams) => {
    setLoader(true);

    // Include seed in queryParams if we have it
    const paramsWithSeed = { ...queryParams };
    if (seed) {
      paramsWithSeed.seed = seed;
    }

    try {
      const response = await ShowAllStudentsForSearch(paramsWithSeed);
      if (response.status === 200) {
        const responseData = response.data;
        const students = responseData.users.data;

        // Update seed from the response
        const seedFromResponse = responseData.seed;
        if (seedFromResponse && seedFromResponse !== seed) {
          setSeed(seedFromResponse);
        }

        setDisplayedStudents(students);
        setTotalPages(responseData.users.last_page);
        setLoader(false);

        // Only save to localStorage if no filters are applied
        const filterParams = { ...queryParams };
        delete filterParams.page; // Exclude 'page' from filters

        const filtersApplied = Object.keys(filterParams).length > 0;

        if (!filtersApplied) {
          // Save data to localStorage
          localStorage.setItem(
            "studentsData",
            JSON.stringify({
              students,
              queryParams,
              seed: seedFromResponse,
              totalPages: responseData.users.last_page,
            })
          );
        } else {
          if (currentPage === 1) {
            Swal.fire({
              title: "Students Found.",
              timer: 1500,
              icon: "success",
              showConfirmButton: false,
            });
          }
        }
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message || "An error occurred",
        timer: 1500,
        icon: "error",
        showConfirmButton: false,
      });
      setLoader(false);
    }
  };

  const handlePageChange = (page) => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      page: page.toString(),
    });
  };

  const paginationItems = [];
  const offset = 1;
  for (let number = 1; number <= totalPages; number++) {
    if (number === 1 || number === totalPages || (number >= currentPage - offset && number <= currentPage + offset)) {
      paginationItems.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => {
            handlePageChange(number);
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
        >
          {number}
        </Pagination.Item>
      );
    } else if (paginationItems[paginationItems.length - 1]?.type !== Pagination.Ellipsis) {
      paginationItems.push(<Pagination.Ellipsis key={`ellipsis-${number}`} disabled />);
    }
  }

  return (
    <div className="viewAllStudents">
      <Container className="text-center">
        <h2 className="mt70">
          Search <span className="green">Students</span>
        </h2>
        <div style={{ position: "relative" }}>
          <span>
            <img className="info3" src="/assets/images/newlogos/Info.svg" alt="" />
            <span className="hoverTextForR3">
              Search for students you're looking to hire by entering your location and task. The advanced search filter enables you to narrow your
              search for more precise results. Click on the profile card of each student to see more information.
            </span>
          </span>
        </div>
        <AdvacnceSearchBar
          searchedValue={setSearchedValue}
          seed={seed}
          updateSeed={(newSeed) => {
            if (newSeed && newSeed !== seed) {
              setSeed(newSeed);
            }
          }}
        />

        {loader ? (
          <div className="height100vh">
            <Loader />
          </div>
        ) : (
          <>
            <Row className="viewAllStCards" id="allStudentSection">
              {displayedStudents?.map((singleStd) => {
                if (singleStd?.email_verified_at !== null) {
                  return (
                    <Col key={singleStd.id} md={4} className="mb-5">
                      <StudentProfileCard
                        searchedValue={searchedValue}
                        bookNow={bookNow}
                        studentData={singleStd}
                        setAllStudent={setDisplayedStudents}
                      />
                    </Col>
                  );
                }
                return null;
              })}
            </Row>
          </>
        )}
        <div className="pagination-controls d-flex justify-content-center">
          <Pagination>
            <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
            {paginationItems}
            <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
          </Pagination>
        </div>
      </Container>
    </div>
  );
};

export default ViewAllStudents;
