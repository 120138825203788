import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import FooterNav from '../mobilefooternav/FooterNav'
import { initializeApp } from "firebase/app";
import { useDebouncedCallback } from "use-debounce";
import {
  collection as fireStoreCollectione,
  query as fireStoreQuery,
  where as fireStoreWhere,
  getDocs as fireStoreDocs,
  onSnapshot,
  getFirestore,
  addDoc,
  orderBy,
  Timestamp,
  doc,
  updateDoc,
  // Firestore,
} from "firebase/firestore";
import { firebaseConfig } from "../../firebase/FireBase";
import ChatRooms from "./ChatRooms";
import ChatRespHed from "./ChatRespHed";
import moment from "moment";
import { days } from "../../services/availibility&timeslots/Availibility";
import _ from "lodash";
import Swal from "sweetalert2";
import { sendReminderForChat } from "../../services/chat/SendReminderForChat";
import singleTick from "../../assets/icons/single-tick.svg";
import doubleTick from "../../assets/icons/double-tick.svg";

const StudentChatBar = () => {
  // Two components for chat : ChatBar and ChatRooms
  const [loder, setLoder] = useState(true);
  const [loder2, setLoder2] = useState(false);
  // const [activeUser, setActiveUser] = useState(true);
  const [senderId, setSenderId] = useState();
  const serverTime = Timestamp.now().toMillis(); // Get milliseconds since epoch

  // chat starts from here
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  let user_id = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.id.toString();
  let user_Image = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.image;
  let user_Role = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.role;
  let user_Name = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.first_name;

  const [chatRooms, setChatRooms] = useState([]);
  const [roomId, setRoomId] = useState("");
  const [receiverImg, setReciverImage] = useState("");
  const [receiverName, setReciverName] = useState("");
  const [users, setUsers] = useState([]);
  const [countUsers, setCountUsers] = useState(0);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  // const [chatRoomSelected, setChatRoomSelected] = useState("")

  //1. setting list of users
  useEffect(() => {
    if (countUsers === 0) {
      getUsers(db);
      setCountUsers(1);
    }
  }, [countUsers]);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();
  const roomIdFromUrl = query.get("roomId");

  async function getUsers(db) {
    const roomsCol = fireStoreQuery(fireStoreCollectione(db, "ChatRooms"), fireStoreWhere("userIds", "array-contains", user_id));
    const roomsSnapshot = await fireStoreDocs(roomsCol);
    const roomsList = roomsSnapshot.docs.map((doc) => doc.data());
    const sortedChatRooms = _.sortBy(roomsList, "timeStampOfLastMessage").reverse();

    setChatRooms(sortedChatRooms);
    setLoder(false);

    if (roomIdFromUrl) {
      const selectedRoom = sortedChatRooms.find((room) => room.id === roomIdFromUrl);
      if (selectedRoom) {
        // Assuming you have a function to select a room and load messages
        selectRoomAndLoadMessages(selectedRoom);
      }
    }
  }

  const selectRoomAndLoadMessages = async (selectedRoom) => {
    setRoomId(selectedRoom.id);
  };

  // heavy SnapShot function
  // , { includeMetadataChanges: true }
  useEffect(() => {
    let unsubscribe = null; // Variable to store the unsubscribe function

    const fetchDocument = async () => {
      try {
        const q12 = fireStoreQuery(fireStoreCollectione(db, "ChatRooms"));
        unsubscribe = onSnapshot(q12, (snapshot) => {
          const chatRoomList = snapshot.docs.map((doc) => doc.data());
          const unreadMessage = chatRoomList?.filter((item) => {
            return item?.seen === false || item?.userIds?.includes(user_id);
          });
          const unreadMessageForUser = unreadMessage?.filter((item) => {
            return item?.userIds?.includes(user_id);
          });
          const sortedChatRooms = _.sortBy(unreadMessageForUser, "timeStampOfLastMessage").reverse();

          setChatRooms(sortedChatRooms);
        });
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };
    fetchDocument();
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [db, user_id]);

  useEffect(() => {
    const getInitialData = async () => {
      const q = fireStoreQuery(fireStoreCollectione(db, "ChatRooms"), fireStoreWhere("id", "==", roomIdFromUrl));
      const currentUserId = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.id;

      const roomsSnapshot = await fireStoreDocs(q);
      // const roomsListId = roomsSnapshot.docs.map((doc) => ({
      //   id: doc.data().userIds[1],
      // }))[0];
      const roomsListId = roomsSnapshot.docs.map((doc) => {
        const userIds = doc.data().userIds;
        // Filter out the current user's ID and return the other ID
        const otherUserId = userIds.find((id) => parseInt(id) !== parseInt(currentUserId));
        return { id: otherUserId };
      })[0];
      getMessages(roomsListId, roomIdFromUrl, null);
    };
    if (roomIdFromUrl) {
      getInitialData();
    }
  }, []);

  //4. showing All Messages in chat on Click of userList and then 5. || chatClass is used for responsive purpose
  const [chatClass, setChatClass] = useState("chatBar");
  const [chatStartedTextOnly, setChatStartedTextOnly] = useState(false);

  const getMessages = async (data, roomID, e) => {
    // This state is only for showing "chat has started" text
    setChatStartedTextOnly(true);

    setRoomId(roomID);
    setSenderId(data?.id);

    // Adding RespChat for responsive purpose
    setChatClass("chatBar RespChat");

    // Adding active class
    document.querySelectorAll(".chatBody").forEach((item) => {
      if (e?.target?.closest(".chatBody") === item) {
        item?.classList?.add("chatBodyActive");
      } else {
        item?.classList?.remove("chatBodyActive");
      }
    });

    // Mark the chatroom as seen
    const q = fireStoreQuery(fireStoreCollectione(db, "ChatRooms"), fireStoreWhere("id", "==", roomID));
    const roomsSnapshot = await fireStoreDocs(q);
    const roomsListId = roomsSnapshot.docs.map((doc) => doc.id);
    const roomListIdString = roomsListId.toString();

    await updateDoc(doc(db, "ChatRooms", roomListIdString), {
      seen: true,
    });

    // Fetch messages and update their 'read' status
    await getMessagesData(db, data, roomID);
  };

  const getMessagesData = async (db, data, roomID) => {
    // Fetching user details remains the same
    const q12 = fireStoreQuery(fireStoreCollectione(db, "Users"), fireStoreWhere("id", "==", data?.id));
    const roomsSnapshot12 = await fireStoreDocs(q12);
    const roomsListId12 = roomsSnapshot12.docs.map((doc) => doc.data());
    setReciverImage(roomsListId12[0].imageUrl);
    setReciverName(roomsListId12[0].name);

    // Getting messages from Firebase
    const messagesCol = fireStoreQuery(fireStoreCollectione(db, "Messages"), fireStoreWhere("roomId", "==", roomID), orderBy("timeStamp", "asc"));

    const messagesSnapshot = await fireStoreDocs(messagesCol);

    const messagesList = [];

    for (const doc of messagesSnapshot.docs) {
      const messageData = doc.data();

      if (messageData.senderId !== user_id && !messageData.read) {
        await updateDoc(doc.ref, { read: true });
        messageData.read = true; // Manually set 'read' to true in the local object
      }

      // Collect message data
      messagesList.push({
        senderId: messageData.senderId,
        roomId: messageData.roomId,
        timeStamp: parseInt(messageData.timeStamp), // Ensure it's a number
        message: messageData.message,
        url: messageData.url,
        delivered: messageData.delivered,
        read: messageData.read,
      });
    }

    // Sort messages by timestamp (number)
    const sortedMessages = messagesList.sort((a, b) => a.timeStamp - b.timeStamp);

    setMessages(sortedMessages);
    setUsers(data);
  };

  useEffect(() => {
    if (roomId !== "") {
      const q = fireStoreQuery(fireStoreCollectione(db, "Messages"), fireStoreWhere("roomId", "==", roomId), orderBy("timeStamp", "asc"));
      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        const messagesArray = [];

        for (const doc of querySnapshot.docs) {
          const messageData = doc.data();

          // Update 'delivered' status for messages sent to the user
          if (messageData.senderId !== user_id && !messageData.delivered) {
            await updateDoc(doc.ref, { delivered: true });
          }

          // **Update 'read' status for messages sent to the user**
          if (messageData.senderId !== user_id && !messageData.read) {
            await updateDoc(doc.ref, { read: true });
          }

          // Collect message data
          messagesArray.push({
            senderId: messageData.senderId,
            roomId: messageData.roomId,
            timeStamp: parseInt(messageData.timeStamp),
            message: messageData.message,
            url: messageData.url,
            delivered: messageData.delivered,
            read: messageData.read || messageData.senderId !== user_id,
          });
        }

        // Sort messages by timestamp
        const sortedMessages = messagesArray.sort((a, b) => a.timeStamp - b.timeStamp);

        setMessages(sortedMessages);
      });

      // Clean up the listener on unmount
      return () => {
        unsubscribe();
      };
    }
  }, [db, roomId]);

  async function saveMessage(e) {
    e.preventDefault();
    if (message !== "") {
      try {
        await addDoc(fireStoreCollectione(db, "Messages"), {
          message: message,
          roomId: roomId,
          senderId: user_id,
          timeStamp: serverTime, // Use the milliseconds timestamp
          url: "",
          read: false, // `read` can mean seen
          delivered: false, // New field for message delivery
        });

        // Update the chat room's last message and timestamp
        const q = fireStoreQuery(fireStoreCollectione(db, "ChatRooms"), fireStoreWhere("id", "==", roomId));
        const roomsSnapshot = await fireStoreDocs(q);
        const roomsListId = roomsSnapshot.docs.map((doc) => doc.id);
        const roomListIdString = roomsListId.toString();

        await updateDoc(doc(db, "ChatRooms", roomListIdString), {
          lastmessage: message,
          timeStampOfLastMessage: serverTime, // Use the milliseconds timestamp
          senderId: user_id,
          seen: false,
          delivered: true,
        });
        setMessage("");
      } catch (error) {
        console.error("Error writing new message to Firebase Database", error);
      }
    } else {
      return false;
    }
  }

  // keeping the chat at the bottom
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const appendRoomId = (room_id) => {
    const currentHash = window.location.hash; // Get the hash part of the URL (e.g., #/dashboardRoute/chatStudent)
    const basePath = currentHash.split("?")[0]; // Extract the base path without existing query parameters
    const searchParams = new URLSearchParams(currentHash.split("?")[1]); // Get existing query parameters, if any
    searchParams.set("roomId", room_id); // Add or update the roomId query parameter
    const newHash = `${basePath}?${searchParams.toString()}`;

    // Update the hash in the URL
    window.history.replaceState(null, "", `${window.location.pathname}${newHash}`);
  };

  const sendReminder = async (sender_id, receiver_id, last_message, room_id) => {
    const data = {
      sender_id: sender_id,
      receiver_id: receiver_id,
      last_message: last_message,
      room_id: room_id,
    };

    const response = await sendReminderForChat(data);

    if (response.data.status === 200) {
      Swal.fire({
        title: response.data.message,
        timer: 1500,
        icon: "success",
        showConfirmButton: false,
      });
    } else if (response.data.status === 201) {
      Swal.fire({
        title: response.data.message,
        timer: 1500,
        icon: "info",
        showConfirmButton: false,
      });
    } else {
      Swal.fire({
        title: response.data.message,
        timer: 1500,
        icon: "error",
        showConfirmButton: false,
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Searching & Filtering

  function isTrue(arr, arr2) {
    return arr.every((i) => arr2.includes(i));
  }

  const debounced = useDebouncedCallback(async function (e) {
    // setting message list empty onChange
    setMessages([]);
    // starts filtering
    let finalUsers = [];

    if (e.target.value) {
      setLoder(true);

      const roomsCol = fireStoreQuery(fireStoreCollectione(db, "ChatRooms"), fireStoreWhere("userIds", "array-contains", user_id));
      const roomsSnapshot = await fireStoreDocs(roomsCol);
      const roomsList = roomsSnapshot.docs.map((doc) => doc.data());
      roomsList.forEach((item) => {
        item.userIds.forEach((item) => {
          if (user_id !== item) finalUsers.push(item);
        });
      });

      const finalChatRooms = [];

      const FilteredChatRooms = finalUsers.map(async (user) => {
        const usersCol = fireStoreQuery(fireStoreCollectione(db, "Users"), fireStoreWhere("id", "==", user));
        const usersSnapshot = await fireStoreDocs(usersCol);
        const usersList = usersSnapshot.docs.map((doc) => doc.data());

        if (usersList[0]?.name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1) {
          const rooms1Col = fireStoreQuery(fireStoreCollectione(db, "ChatRooms"), fireStoreWhere("userIds", "array-contains", usersList[0].id));
          const rooms1Snapshot = await fireStoreDocs(rooms1Col);
          const rooms1List = rooms1Snapshot.docs.map((doc) => doc.data());
          rooms1List.forEach((item) => {
            if (isTrue(item.userIds, [usersList[0].id, user_id])) {
              finalChatRooms.push(item);
            }
          });
        }
      });

      await Promise.all(FilteredChatRooms);

      setLoder(false);
      setChatRooms(finalChatRooms);
    } else if (e.target.value === "") {
      setLoder(true);
      const roomsCol = fireStoreQuery(fireStoreCollectione(db, "ChatRooms"), fireStoreWhere("userIds", "array-contains", user_id));
      const roomsSnapshot = await fireStoreDocs(roomsCol);
      const roomsList = roomsSnapshot.docs.map((doc) => doc.data());
      setChatRooms(roomsList);
      setLoder(false);
    }
  }, 1500);

  // responsive functionality removing for showing and removing classes and getting respitem from chatroom here to show single user data for name and image in responsive

  const handleClick = () => {
    setChatClass("chatBar");
  };

  const formatTime = (timestamp) => {
    const date = new Date(parseInt(timestamp)); // Ensure timestamp is a number

    const day = String(date.getDate()).padStart(2, "0"); // DD
    const month = date.toLocaleString("en-US", { month: "short" }); // MMM
    const year = date.getFullYear(); // YYYY

    let hours = date.getHours(); // Get hours in 24-hour format
    const minutes = String(date.getMinutes()).padStart(2, "0"); // MM
    const ampm = hours >= 12 ? "PM" : "AM"; // Determine AM/PM

    // Convert 24-hour time to 12-hour time
    hours = hours % 12;
    hours = hours ? hours : 12; // Replace 0 with 12 for midnight

    // Return the formatted string
    return `${month} ${day}, ${year} ${hours}:${minutes} ${ampm}`;
  };

  return (
    <div>
      {/*- In chatClass we are adding another class for which we are making responsive
            - setResonive1 class will displayed block on mobile and none on desktop */}
      <div className={chatClass}>
        <Row>
          <Col md={4} className="borderRight">
            <div className="chatMain">
              {/* this one is for desktop */}
              <div className="chatHed setResonive adjustChatHed">
                <input
                  className="filterInput"
                  type="text"
                  placeholder={user_Role === "client" ? "Search Student" : "Search Client"}
                  onChange={(e) => debounced(e)}
                />
                <div>
                  <img className="chatSearch" src="/assets/images/cutomerchatscreen/Searchvector.svg" alt="" />
                </div>
              </div>
              {/* this one is for responsive */}
              <div className="ChatResonive1NamesMain setResonive1">
                <h2>Chat</h2>
                <div className="customSearchField flex2 chatSrch">
                  <input
                    type="text"
                    className="findTasksField"
                    placeholder={user_Role === "client" ? "Search Student" : "Search Client"}
                    onChange={(e) => debounced(e)}
                  />
                  <button className="taskbutton primary center">SMS</button>
                </div>
              </div>
              {/* 2. Chatroom work */}
              {loder ? (
                <div className="height100vh">
                  <div className="lds-spinner chotaLoder">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              ) : (
                <div className="adjustingHeight">
                  {chatRooms?.map((item) => {
                    return (
                      <ChatRooms
                        key={item?.id}
                        userId={item?.userIds}
                        getMessages={getMessages}
                        roomId={item?.id}
                        userTime={item?.timeStampOfLastMessage}
                        lastMessage={item?.lastmessage}
                        lastTime={item?.timeStampOfLastMessage}
                        seen={item?.seen}
                        // chatRoomSelected={chatRoomSelected}
                        messageSenderId={item?.senderId}
                        isActive={roomId === item.id}
                        appendRoomId={appendRoomId}
                        sendReminder={sendReminder}
                        // recieverId={item?.recieverId}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          </Col>
          <Col md={8} className="setResonive">
            {/* setResonive1 means block in mobile || exporting chatRooms again only for responsive as user will click on the person to chat so on top image,name will be shown of that person || .chatBar.RespChat .setResonive.col-md-8  and .chatBar.RespChat .borderRight.col-md-4 for previous css we are adding RespChat class*/}
            <div className="singleChathed setResonive1 pb0">
              <div>
                <Link to="" onClick={handleClick}>
                  {/* <img src="/assets/images/backArrw.png" alt="" /> */}
                  <img src="/assets/images/arrw.png" style={{ width: "14px", height: "12px" }} alt="" />
                </Link>
              </div>
              <ChatRespHed userData={senderId} />
            </div>
            {/*setResonive means block on desktop and none on responsive  */}
            <div className="chatHed setResonive">
              <h5>Chat</h5>
            </div>
            <div className="adjustingHeight2">
              {chatStartedTextOnly && <p className="msgStartP">The chat has started</p>}
              {loder2 ? (
                <div className="height100vh">
                  <div className="lds-spinner chotaLoder">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              ) : (
                messages?.map((msg) => {
                  const isSentByCurrentUser = msg?.senderId === user_id;
                  // Find the last message in the array for the current user (sender)
                  const lastMessageByCurrentUser = [...messages].reverse().find((message) => message.senderId === user_id);

                  // Find the last message in the array for the receiver
                  const lastMessageByReceiver = [...messages].reverse().find((message) => message.senderId !== user_id);

                  return (
                    <div key={msg.timeStamp}>
                      {isSentByCurrentUser ? (
                        <div className="floatRightContent">
                          <div className="rContentDiv">
                            <div>
                              <p className="rContentHed">{user_Name}</p>
                              <p className="rContentP">{formatTime(msg?.timeStamp)}</p>
                            </div>
                            <div>
                              <img className="InnerImageChat" src={user_Image} alt="" />
                            </div>
                          </div>
                          {/* Message and Tick Icons */}
                          <div className="messageRow rightMessage">
                            <div className="typedMsgs">
                              <p className="typedMsgsP text-break">{msg?.message}</p>
                            </div>

                            {/* Display status below the message */}
                            {lastMessageByCurrentUser?.timeStamp === msg.timeStamp && (
                              <div className="messageStatus right">
                                {!msg.delivered && (
                                  <>
                                    <img src={singleTick} alt="Sending" style={{ width: "15px" }} />
                                    <span>Sent</span>
                                  </>
                                )}
                                {msg.delivered && !msg.read && (
                                  <>
                                    <img src={singleTick} alt="Delivered" style={{ width: "15px" }} />
                                    <span>Delivered</span>
                                  </>
                                )}
                                {msg.read && (
                                  <>
                                    <img src={doubleTick} alt="Seen" style={{ width: "15px", paddingRight: "1px" }} />
                                    <span style={{ color: "#82d051" }}>Seen</span>
                                  </>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="floatleftContent">
                          <div className="lContentDiv">
                            <div>
                              <img className="InnerImageChat" src={receiverImg} alt="" />
                            </div>
                            <div>
                              <p className="rContentHed">{receiverName}</p>
                              <p className="rContentP">{formatTime(msg?.timeStamp)}</p>
                            </div>
                          </div>
                          {/* Message Bubble Only */}
                          <div className="rTypedMsgs">
                            {msg?.url ? (
                              <Link className="typedMsgsP text-break notifiP" to={msg.url}>
                                {msg?.message}
                              </Link>
                            ) : (
                              <p className="typedMsgsP text-break">{msg?.message}</p>
                            )}
                          </div>
                        </div>
                      )}
                      <div ref={messagesEndRef} />
                    </div>
                  );
                })
              )}
            </div>

            <form onSubmit={(e) => saveMessage(e)} className="writeMessages">
              <div className="writeMessages1stdiv">
                <input value={message} className="inputMsgField" placeholder="Message" type="text" onChange={(e) => setMessage(e.target.value)} />
              </div>
              <div className="elementCenter">
                <button className="primary sendMsgBtn" type="submit">
                  Send
                </button>
              </div>
            </form>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default StudentChatBar;
