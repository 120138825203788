import axios from "../axios";

export const ShowAllStudentsForSearch = async (filteredQuery = {}, page = 1, seed = null) => {
  try {
    const token = JSON.parse(localStorage.getItem("sparrowSignIn"))?.token;

    const headers = {};
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    filteredQuery = filteredQuery || {};
    filteredQuery.page = filteredQuery.page || page;

    if (seed) {
      filteredQuery.seed = seed;
    } else {
      const randomSeed = generateRandomSeed(); // Generate numeric seed
      filteredQuery.seed = randomSeed;
    }

    const queryParams = new URLSearchParams(filteredQuery).toString();

    const response = await axios.get(`/getAllStudentsForSearch?${queryParams}`, {
      headers,
    });

    return response;
  } catch (err) {
    return err.response;
  }
};

function generateRandomSeed() {
  const randomString = Math.random().toString(36).substring(2, 10) + Math.random().toString(36).substring(2, 10);
  let numericSeed = 0;
  for (let i = 0; i < randomString.length; i++) {
    numericSeed += randomString.charCodeAt(i) * (i + 1);
  }
  return numericSeed;
}
