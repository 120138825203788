import { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Moment from "react-moment";
import { getLocations } from "../../services/locations/Locations";
import { showStudent } from "../../services/users/ShowSingleStudent";
import { days, timeSlots } from "../../services/availibility&timeslots/Availibility";
import { singleUserAvailability } from "../../services/availibility&timeslots/SingleUserAvailability";
import { getStudentOrders } from "../../services/order/StudentOrderList";
import StudentReview from "../studenttaskscreens/studentModal/StudentReview";
import { sendEmail } from "../../services/contactus/ContactUs";
import { createNotification } from "../../services/notifications/CreateNotification";
import { inviteStudent } from "../../services/invitestudents/InviteStudent";
import { emailUrl } from "../../utils/constants";
import Loader from "../loader/Loader";
import { isInvited } from "../../services/tasks/IsInvited";
import { getSingleTask } from "../../services/tasks/GetSingleTask";

const CustomerStudentProfile = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  /* this is for student review modal */
  const [stdReviewShow, setStdReviewShow] = useState(false);
  const handleStdReviewClose = () => setStdReviewShow(false);

  let { userID } = useParams();

  const [current_student, setCurrentStudent] = useState(false);
  const [loder, setLoder] = useState(true);
  const [alreadyInvited, setAlreadyInvited] = useState(false);

  const fetchData = async () => {
    const current_student = await showStudent(userID);
    if (current_student.status === 200) {
      let data = {
        task_id: parseInt(state?.query?.taskId),
        student_id: current_student.data.user?.id,
      };
      const invited_status = await isInvited(data);
      setAlreadyInvited(invited_status?.data);
      setCurrentStudent(current_student.data.user);
      setLoder(false);

      // const fetchWorkAvalible = async () => {
      const current_student_avalible = await singleUserAvailability(current_student.data.user?.id);
      if (current_student_avalible.status === 200) {
        setStudentWorkAvaliblity(current_student_avalible.data.availabilities);
        setWorkAvalible(true);
      }
      // }
    }
  };

  const driverLicense =
    current_student?.student_details?.have_driving_licence == 1
      ? "/assets/images/customerDashboard/circleSuccess.svg"
      : "/assets/images/customerDashboard/crossvector.svg";
  const haveCar =
    current_student?.student_details?.have_car == 1
      ? "/assets/images/customerDashboard/circleSuccess.svg"
      : "/assets/images/customerDashboard/crossvector.svg";

  // get locations
  const [locations, setlocations] = useState([]);
  const locationsFunc = async () => {
    const resp = await getLocations();
    setlocations(resp.data);
  };

  // get working days
  const [workdays, setworkdays] = useState();
  const getDaysFunc = async () => {
    const response = await days();

    if (response.status === 200) {
      // order for monday on top
      let order = {
        monday: 1,
        tuesday: 2,
        wednesday: 3,
        thursday: 4,
        friday: 5,
        saturday: 6,
        sunday: 7,
      };
      let daysArray = response.data;
      daysArray.sort(function (a, b) {
        return order[a.day] - order[b.day];
      });

      setworkdays(daysArray);
      setLoder(false);
    } else {
      Swal.fire({
        title: response.data.message,
        timer: 1500,
        icon: "error",
        showConfirmButton: false,
      });
    }
  };

  // get timing slots
  const [timeSlot, setTimeSlot] = useState();
  const getTimeFunc = async () => {
    const response1 = await timeSlots();

    if (response1.status === 200) {
      setTimeSlot(response1.data);
      setLoder(false);
    } else {
      Swal.fire({
        title: response1.data.message,
        timer: 1500,
        icon: "error",
        showConfirmButton: false,
      });
    }
  };

  useEffect(() => {
    locationsFunc();
    getDaysFunc();
    getTimeFunc();

    //to be change in new effect
  }, []);

  const [current_student_avalibilty, setStudentWorkAvaliblity] = useState();
  const [workAvalible, setWorkAvalible] = useState(false);

  const [current_student_taskReview, setStudentTaskReview] = useState();
  const fetchTaskReviews = async () => {
    const current_student_task_review = await getStudentOrders(userID);
    if (current_student_task_review.status === 200) {
      setStudentTaskReview(current_student_task_review.data);
    }
  };

  let validTaskReview = [];
  current_student_taskReview?.orders?.map((singleReview) => {
    if (singleReview?.feedback) {
      validTaskReview.push(singleReview);
    }
  });

  let WeekDayswithData = current_student_avalibilty?.map(({ day }) => day);

  const searchtimeID = (nameKey, myArray) => {
    // debugger
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i].slot == nameKey) {
        return myArray[i].id;
      }
    }
  };

  const searchdayID = (nameKey, myArray) => {
    //  debugger
    if (myArray) {
      for (var i = 0; i < myArray.length; i++) {
        if (myArray[i].day == nameKey) {
          return myArray[i].id;
        }
      }
    } else {
      return;
    }
  };
  const arrayProp = [];
  {
    current_student_avalibilty?.map((mainItem) => {
      let dayID = searchdayID(mainItem.day, workdays);
      {
        timeSlot &&
          mainItem.avail?.map((item_inner, index) => {
            let startID = searchtimeID(item_inner.start, timeSlot);
            let endID = searchtimeID(item_inner.end, timeSlot);
            arrayProp.push({
              start_time_slot_id: startID,
              end_time_slot_id: endID,
              day_id: dayID,
              // user_id: JSON.parse(localStorage.getItem("sparrowSignIn")).user.id,
            });
          });
      }
    });
  }

  useEffect(() => {
    fetchData();
    // fetchWorkAvalible();
    fetchTaskReviews();
  }, []);
  // call the function

  const getAge = (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  // inviting Student Func
  let user_role = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.role;
  let client_id = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.id;
  let client_Name = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.first_name;
  let client_Email = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.email;
  let isLoggedIn = JSON.parse(localStorage.getItem("sparrowSignIn"));
  const [classOfInvite, setClassOfInvite] = useState("primary profileBtnSet");

  const [btnLoder, setBtnLoder] = useState(false);
  let objectLength;
  if (state?.query) {
    objectLength = Object.keys(state?.query)?.length;
  }

  const inviteStudentFunc = async (std_id, std_email) => {
    setBtnLoder(true);
    // creating invite students number for record
    const data2 = {
      task_id: parseInt(state?.query?.taskId),
      client_id: client_id,
      student_id: std_id,
    };
    const response = await inviteStudent(data2);
    const task = await getSingleTask(state?.query?.taskId);
    if (response.status === 200) {
      // const data = {
      //     fcmToken: studentData?.fcm_token,
      //     title: `Order Recieved from ${client_Name}`,
      //     body: `Order Recieved from ${client_Name}`
      // }
      // const resp = await sendNotification(data)

      // creating chatRoom here
      // let std_String = std_id.toString()
      // let client_String = client_id.toString()
      // createChatRoom(std_String, client_String, taskId, orderId)

      // creating notifiaction for All notififcation tab
      const data1 = {
        // as reqirement by backend type is 0
        type: 0,
        to_id: std_id,
        from_id: client_id,
        data: {
          title: "Task Recieved",
          message: `Task recieved from ${client_Name?.charAt(0).toUpperCase() + client_Name?.slice(1)}. `,
          action_url: `/dashboardRoute/task-history/student-task-descp/${state?.query?.taskId}/${state?.query?.orderId}`,
        },
        read_at: null,
      };
      const resp1 = await createNotification(data1);
      // sending email
      const dataForEmail = {
        to: std_email,
        subject: state?.query?.tasktitle,
        from: client_Email,
        first_name: state?.query?.stdFirstName,
        last_name: state?.query?.stdLastName,
        client_name: client_Name?.charAt(0).toUpperCase() + client_Name?.slice(1),
        msg: state?.query?.taskMsg,
        action_url: `${emailUrl}/#/dashboardRoute/task-history/student-task-descp/${state?.query?.taskId}/${state?.query?.orderId}`,
        template: "student_invited",
        task_details: `${task?.data?.task?.task_details?.title} - $${task?.data?.task?.task_details?.hourly_rate}/hr`,
      };

      await sendEmail(dataForEmail);
      // if (resp.status === 200 && resp1.status === 200 && response.status === 200) {
      // if (resp1.status === 200 && response.status === 200) {
      setClassOfInvite("primary profileBtnSet disalbed");
      //     // console.log("Noti Created")
      setBtnLoder(false);
      Swal.fire({
        title: "Student has been invited!",
        timer: 2500,
        icon: "success",
        showConfirmButton: false,
      });
      setTimeout(() => {
        navigate(`/dashboardRoute/all-students/invite-students/${state?.query?.taskId}/${state?.query?.orderId}`);
      }, 3000);

      // }
    } else {
      Swal.fire({
        title: "You have reached the invitations limit for today",
        timer: 2500,
        icon: "error",
        showConfirmButton: false,
      });
      setBtnLoder(false);
    }
  };
  //

  const handleConnect = () => {
    // Save current location
    if (JSON.parse(localStorage.getItem("sparrowSignIn")) === null) {
      sessionStorage.setItem("redirectAfterSignUp", `/dashboardRoute/all-students/create-tasks/${current_student?.id}/${current_student?.fcm_token}`);
    }
  };

  return (
    <>
      {loder ? (
        <div className="height100vh height10vh">
          <Loader />
        </div>
      ) : (
        <div className="bgNotiLines">
          <Container>
            <h2 className="mb-5 text-center">
              Student <span className="green">Profile</span>
            </h2>
            <Row>
              <Col md={4}>
                <div className="studentDetails">
                  <h4 className="mb-3">student Details</h4>
                  <div className="studentDetailsImg badgeStdnt1">
                    <img src={current_student?.image} alt="" />
                    {current_student?.totalOrders > 50 ? (
                      <div className="hoverTextDiv">
                        <img className="badgeStdnt" src="/assets/images/badges/goldA.svg" alt="" />
                        <span className="hoverText setResonive">This student has done over 100 tasks</span>
                      </div>
                    ) : current_student?.totalOrders > 25 && current_student?.totalOrders <= 50 ? (
                      <div className="hoverTextDiv">
                        <img className="badgeStdnt" src="/assets/images/badges/silverb.svg" alt="" />
                        <span className="hoverText setResonive">This student has done 25-50 tasks</span>
                      </div>
                    ) : (
                      <div className="hoverTextDiv">
                        <img className="badgeStdnt" src="/assets/images/badges/bronzec.svg" alt="" />
                        <span className="hoverText setResonive">This student has done 0-25 tasks</span>
                      </div>
                    )}
                  </div>

                  <h5 className="upH5">
                    {current_student?.first_name?.charAt(0)?.toUpperCase() + current_student?.first_name?.slice(1)}

                    {current_student.last_name && " " + current_student?.last_name.charAt(0).toUpperCase() + "."}
                    {current_student?.student_details?.dob && " " + getAge(current_student?.student_details?.dob) + "'"}
                  </h5>
                  <h5 className="belowH5">{current_student?.student_details?.grade} </h5>
                  <div className="studentContent ml-1 mlllCSP">
                    {current_student?.student_details?.college && (
                      <div className="mb-3 block ml-3">
                        <img src="/assets/images/customerDashboard/capvector.svg" alt="" />
                        <span className="ml-7">{current_student?.student_details?.college} </span>
                      </div>
                    )}
                    {current_student?.location && (
                      <div className="mb-3 block ml-3">
                        <img src="/assets/images/customerDashboard/lcoationvector.svg" alt="" />
                        <span className="ml-7">
                          {current_student?.location?.city}, {current_student?.location?.state}
                        </span>
                      </div>
                    )}
                    <div className="block ml-3">
                      <img src="/assets/images/customerDashboard/verified-dashboar.svg" alt="" />
                      <span className="ml-7">
                        {""}
                        {current_student?.email_verified_at ? "Verified" : "Not Verified"}
                      </span>
                    </div>

                    {objectLength > 0 ? (
                      btnLoder ? (
                        <div className="profileBtn mt-4">
                          <div className="relative bookBtnNow pl10">
                            <div className="loader alignLoader invite"></div>
                            <button className="primary profileBtnSet">Invite</button>
                          </div>
                        </div>
                      ) : alreadyInvited === true ? (
                        <div className="profileBtn mt-4">
                          <button className="primary profileBtnSet disalbed">Invited</button>
                        </div>
                      ) : (
                        <div className="profileBtn mt-4">
                          <button className={classOfInvite} onClick={() => inviteStudentFunc(current_student?.id, current_student?.email)}>
                            Invite
                          </button>
                        </div>
                      )
                    ) : user_role != "student" && isLoggedIn ? (
                      <div className="profileBtn mt-5">
                        <Link
                          to={`/dashboardRoute/all-students/create-tasks/${current_student?.id}/${current_student?.fcm_token}`}
                          className="primary profileBtnSet"
                        >
                          Connect
                        </Link>
                      </div>
                    ) : !isLoggedIn ? (
                      <div className="profileBtn mt-5">
                        <Link to={`/signin`} className="primary profileBtnSet" onClick={handleConnect}>
                          Connect
                        </Link>
                      </div>
                    ) : null}
                  </div>
                </div>
              </Col>
              <Col md={8}>
                <div className="aboutMeBox">
                  <div className="row justify-around d-flex">
                    <div className="pr-0 col-md-6 col-4 "></div>
                    <div className="col-8 col-md-6 withPaymentBlock" style={{ textAlign: "right" }}>
                      <span>
                        <img src="/assets/images/customerDashboard/hourlyvector.svg" alt="" />
                      </span>
                      <span className=" ms-1 topHed mb-3 green">Average Rate</span>
                      <span className="timeSlotPills ms-1">
                        ${current_student?.student_details?.minimum_hourly_rate}
                        /hr
                      </span>
                    </div>
                  </div>
                  <p className="aboutMainhed">About Me</p>
                  <p className="aboutContent">{current_student?.student_details?.about}</p>
                  <div className="d-flex mb-4">
                    <div className="mr-28">
                      <p className="tickp">Driver’s License &nbsp;&nbsp;</p>
                      <img src={driverLicense} alt="" />
                    </div>
                    <div>
                      <p className="tickp">Own Vehicle&nbsp;&nbsp;</p>
                      <img src={haveCar} alt="" />
                    </div>
                  </div>
                  <p className="aboutMainhed mb-2">Featured Tasks</p>
                  <div className="d-flex flex-wrap">
                    {current_student?.student_details ? (
                      current_student?.student_details?.skills ? (
                        current_student?.student_details?.skills
                          ?.split(/\s*,\s*/)
                          .sort((a, b) => a.length - b.length) // Sort skills by length
                          .map((index) => {
                            index = index.replaceAll("|", " | ");
                            if (index.toLowerCase().includes("sports")) {
                              return (
                                <span className="fancySpan mb-1" key={index}>
                                  {index.replace("Sports ", "")}
                                </span>
                              );
                            } else {
                              return (
                                <span className="fancySpan mb-1" key={index}>
                                  {index}
                                </span>
                              );
                            }
                          })
                      ) : (
                        <span className="fancySpan mb-1">None</span>
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <Row className="justify-content-between">
                  <Col md={3} xs={6} className="hobbiesLanguageStyle">
                    <div className="aboutMeBox aboutMeBox_lower pointList">
                      <p className="aboutMainhed mb-2 mb5">Hobbies</p>
                      <ul className="aboutList">
                        {current_student?.student_details?.hobbies ? (
                          current_student?.student_details?.hobbies?.split(/\s*,\s*/).map((index) => {
                            return <li key={index}>{index}</li>;
                          })
                        ) : (
                          <>
                            <li> None</li>
                          </>
                        )}
                      </ul>
                    </div>
                  </Col>
                  <Col md={3} xs={6} className="hobbiesLanguageStyle">
                    <div className="aboutMeBox aboutMeBox_lower pointList">
                      <p className="aboutMainhed mb-2 mb5">Languages</p>
                      <ul className="aboutList">
                        {current_student?.student_details?.languages ? (
                          current_student?.student_details?.languages?.split(/\s*,\s*/).map((index) => {
                            return <li key={index}>{index}</li>;
                          })
                        ) : (
                          <>
                            <li> None</li>
                          </>
                        )}
                      </ul>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="aboutMeBox aboutMeBox_lower">
                      <p className="aboutMainhed mb-2">Interesting Fact</p>
                      <p className="aboutContent">
                        {current_student?.student_details?.fun_facts ? current_student?.student_details?.fun_facts : "None"}
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={4}>
                <div className="aboutMeBox">
                  <h4 className="mb-3 text-center block">Work Availability</h4>
                  <div className="mt-5">
                    {workdays?.map((item) => {
                      return (
                        <div className="mb-3 timeSlotD">
                          <div className="workPoint">
                            <div className="state">
                              <label>{item?.day.charAt(0).toUpperCase() + item?.day.slice(1)}</label>
                            </div>
                          </div>

                          {/* if the avaliblity  */}

                          {!current_student_avalibilty && (
                            <div className="workContent">
                              <div className="d-flex mt-2">
                                <span className="fancySpan">Not Available</span>
                              </div>
                            </div>
                          )}

                          {current_student_avalibilty?.map((parentObjArray) => {
                            return (
                              <>
                                {parentObjArray?.day === item?.day && parentObjArray.avail ? (
                                  <div className="workContent">
                                    <div className="d-flex mt-2 flex-wrap">
                                      {parentObjArray.avail?.map((item_inner, index) => {
                                        return (
                                          <span className="fancySpan mb-1">
                                            {item_inner.start}-{item_inner.end}
                                          </span>
                                        );
                                      })}
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </>
                            );
                          })}

                          {WeekDayswithData?.indexOf(item?.day) == -1 ? (
                            <div className="workContent">
                              <div className="d-flex mt-2">
                                <span className="fancySpan mb-3">Not Available</span>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Col>

              {/* Main Review Sec */}
              <Col md={8}>
                <div className="aboutMeBox">
                  {/* mobile */}
                  <h4 className="displaynone-mb-551 "> Reviews ({current_student_taskReview?.reviews[0]?.total_reviews})</h4>
                  <div className="workRateTop">
                    <div className="workRateTopHed">
                      {/* desktop */}
                      <h4>
                        <div>
                          <span className=" displaynone-mb">
                            Reviews(
                            {current_student_taskReview?.reviews[0]?.total_reviews}
                            )&nbsp;
                          </span>
                        </div>
                      </h4>
                    </div>
                  </div>
                  <div className="row mt-4 reviewSection_dashbord">
                    <div className="d-flex flex-column forTaskInReview col-lg-4 col-md-12 col-12">
                      <div className=" d-flex justify-content-between align-items-center mb-2 taskCompleted">
                        <p className="aboutMainhed">Tasks Completed:</p>
                        <p className="taskCompletedP">{current_student_taskReview?.reviews[0]?.total_reviews}</p>
                      </div>
                      <div className=" d-flex justify-content-between align-items-center">
                        <p className="aboutMainhed">Total Rating:</p>
                        <p className="taskCompletedP">
                          {current_student_taskReview?.rating[0]
                            ? parseFloat(current_student_taskReview?.rating[0]?.overall_rating).toFixed(1) + "/5"
                            : current_student_taskReview?.rating?.length > 0
                            ? "0/5"
                            : "5/5"}
                        </p>
                      </div>
                    </div>

                    <div className="ratingFeatures col-lg-8 col-md-12 col-12">
                      <div className="row">
                        <div className="col-6 d-flex justify-content-between ratingFirst">
                          <span className="aboutContent mb-customr-student">Performance:</span>
                          <div className="aboutContent green">
                            {" "}
                            <img className="sStar" src="/assets/images/customerDashboard/Star 8.svg" alt="" />
                            &nbsp;{" "}
                            <span className="valueNextToStar">
                              {current_student_taskReview?.rating[0]
                                ? parseFloat(current_student_taskReview?.rating[0]?.overall_performance).toFixed(1) + "/5"
                                : current_student_taskReview?.rating?.length > 0
                                ? "0/5"
                                : "5/5"}
                            </span>
                          </div>
                        </div>
                        <div className="col-6 d-flex justify-content-between ratingSecound">
                          <span className="aboutContent mb-customr-student">Attitude:</span>
                          <div className="aboutContent green">
                            {" "}
                            <img className="sStar" src="/assets/images/customerDashboard/Star 8.svg" alt="" />
                            &nbsp;{" "}
                            <span className="valueNextToStar">
                              {current_student_taskReview?.rating[0]
                                ? parseFloat(current_student_taskReview?.rating[0]?.overall_attitude).toFixed(1) + "/5"
                                : current_student_taskReview?.rating?.length > 0
                                ? "0/5"
                                : "5/5"}
                            </span>{" "}
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-6 d-flex justify-content-between ratingFirst">
                          <span className="aboutContent mb-customr-student">Communication:</span>
                          <span className="aboutContent green">
                            {" "}
                            <img className="sStar" src="/assets/images/customerDashboard/Star 8.svg" alt="" />
                            &nbsp;{" "}
                            <span className="valueNextToStar">
                              {current_student_taskReview?.rating[0]
                                ? parseFloat(current_student_taskReview?.rating[0]?.overall_communication).toFixed(1) + "/5"
                                : current_student_taskReview?.rating?.length > 0
                                ? "0/5"
                                : "5/5"}
                            </span>
                          </span>
                        </div>
                        <div className="col-6 d-flex justify-content-between ratingSecound">
                          <span className="aboutContent mb-customr-student">Responsibility:</span>
                          <span className="aboutContent green">
                            {" "}
                            <img className="sStar" src="/assets/images/customerDashboard/Star 8.svg" alt="" />
                            &nbsp;{" "}
                            <span className="valueNextToStar">
                              {current_student_taskReview?.rating[0]
                                ? parseFloat(current_student_taskReview?.rating[0]?.overall_responsibility).toFixed(1) + "/5"
                                : current_student_taskReview?.rating?.length > 0
                                ? "0/5"
                                : "5/5"}
                            </span>{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* ratingComemt Reviews Section*/}

                  {validTaskReview?.length > 0 ? (
                    <>
                      <div className="firstSelect">
                        {validTaskReview.slice(0, 4).map((singleReview) => {
                          return (
                            <div className="ratingComment">
                              <div className="ratingCommentHed">
                                <div className="singleRevImg">
                                  <img className="commentImg" src={singleReview?.client?.image} alt="" />
                                </div>
                                <div className="singleRevName">
                                  <p className="aboutMainhed">
                                    {singleReview?.client?.first_name && singleReview?.client?.last_name
                                      ? singleReview?.client?.first_name + " " + singleReview?.client?.last_name.charAt(0).toUpperCase()
                                      : ""}{" "}
                                  </p>

                                  <p className="aboutMainCC">{singleReview?.task?.title}</p>
                                </div>
                                <div className="singleRevRating">
                                  <p className="setCommntContent aboutContent mb-aboutcontent">
                                    {" "}
                                    <span>
                                      <img src="/assets/images/customerDashboard/Star 8.svg" alt="" />
                                    </span>{" "}
                                    <span className="green underline1">
                                      {parseFloat(
                                        (singleReview?.feedback?.responsibility +
                                          singleReview?.feedback?.performance +
                                          singleReview?.feedback?.communication +
                                          singleReview?.feedback?.attitude) /
                                          4
                                      ).toFixed(1)}
                                    </span>
                                  </p>
                                </div>
                              </div>
                              <p className="aboutContent">{singleReview?.feedback?.comment ? singleReview?.feedback?.comment : ""}</p>
                              <p className="publishP">
                                Published <Moment fromNow>{singleReview?.feedback?.created_at}</Moment>
                              </p>
                            </div>
                          );
                        })}
                      </div>

                      <div className="text-center">
                        <StudentReview onClose={handleStdReviewClose} modalValue={stdReviewShow} reviewStudent={validTaskReview} />
                        <a className="workViewAll" style={{ cursor: "pointer" }} onClick={() => setStdReviewShow(true)}>
                          View More
                        </a>
                      </div>
                    </>
                  ) : (
                    <div className="ratingComment pb-0 noTaskForReview">
                      <p className="publishP">No Task Reviews</p>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};

export default CustomerStudentProfile;
