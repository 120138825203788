import { useState, useEffect, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { useSearchParams } from "react-router-dom";
import { timeSlots, days } from "../../services/availibility&timeslots/Availibility";
import { isMobile } from "react-device-detect";
import { colourStyles, colourStylesLocation } from "../../utils/constants";
import { Col, Container, Row, Pagination } from "react-bootstrap";

const AdvacnceSearchBar = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [timeSlot, setTimeSlot] = useState([]);
  const [workdays, setWorkdays] = useState([]);
  const [randomLocation, setRandomLocation] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOption1, setSelectedOption1] = useState(null);
  const [selectedOption2, setSelectedOption2] = useState(null);
  const [selectedValue1, setSelectedValue1] = useState(null);
  const [adv_toBeShow, setAdv_toBeShow] = useState(!isMobile);
  const [filterType, setFilterType] = useState(1);

  const imageForDropDownAdv = adv_toBeShow ? "/assets/images/viewAll/ArrowUp.svg" : "/assets/images/viewAll/ArrowDown.svg";
  let user_id = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.id;

  const {
    control,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      location: null,
      taskInputValue: null,
      daySearch: null,
      timeSearch: null,
    },
  });

  useEffect(() => {
    const loadInitialData = async () => {
      const [timeSlotsRes, daysRes] = await Promise.all([timeSlots(), days()]);
      if (timeSlotsRes.status === 200) setTimeSlot(timeSlotsRes.data);
      if (daysRes.status === 200) {
        let order = {
          monday: 1,
          tuesday: 2,
          wednesday: 3,
          thursday: 4,
          friday: 5,
          saturday: 6,
          sunday: 7,
        };

        // Sort days to start with Monday
        const sortedWorkdays = daysRes.data.sort((a, b) => {
          return order[a.day.toLowerCase()] - order[b.day.toLowerCase()];
        });

        // Create the optionDay array
        const optionDay = sortedWorkdays.map((singleDay) => ({
          label: singleDay.day.charAt(0).toUpperCase() + singleDay.day.slice(1),
          value: singleDay.id,
        }));

        setWorkdays(sortedWorkdays); // Update state with sorted workdays
      }

      // Load locations from localStorage
      const storedLocationsData = localStorage.getItem("locationsData");
      if (storedLocationsData) {
        try {
          setRandomLocation(JSON.parse(storedLocationsData));
        } catch (e) {
          console.error("Error parsing locationsData:", e);
        }
      }
    };

    loadInitialData();

    // Restore filters and pagination from localStorage
    const savedFilters = localStorage.getItem("searchFilters");
    if (savedFilters) {
      const filters = JSON.parse(savedFilters);
      setSelectedOption(filters.rating || null);
      setSelectedOption1(filters.hourlyWage || null);
      setSelectedOption2(filters.transportation || null);
      setSelectedValue1(filters.skills || null);
      setValue("location", filters.location || null);
      setValue("daySearch", filters.day || null);
      setValue("timeSearch", filters.time || null);

      // Set the page in searchParams if stored
      // if (filters.page) {
      //   setSearchParams({ ...filters });
      // }
    }
  }, []);

  const optionDay = workdays.map((day) => ({
    label: day.day.charAt(0).toUpperCase() + day.day.slice(1),
    value: day.id,
  }));

  const optionTime = timeSlot.map((slot) => ({
    label: slot.slot,
    value: slot.id,
  }));

  const populateFormFromQuery = () => {
    const params = Object.fromEntries([...searchParams.entries()]);

    if (params.skills) {
      setSelectedValue1({ label: params.skills, value: params.skills });
    }

    if (params.rating) {
      setSelectedOption(params.rating);
    }

    if (params.day) {
      const dayOption = optionDay.find((option) => option.value.toString() === params.day.toString());
      if (dayOption) {
        setValue("daySearch", dayOption);
      }
    }

    if (params.time) {
      const timeOption = optionTime.find((option) => option.value.toString() === params.time.toString());
      if (timeOption) {
        setValue("timeSearch", timeOption);
      }
    }

    if (params.vehicle === "1") {
      setSelectedOption2("OwnVehicle");
    } else if (params.licence === "1") {
      setSelectedOption2("driverLicense");
    } else {
      setSelectedOption2(null);
    }

    if (params.minimum_hourly_rate && params.maximum_hourly_rate) {
      setSelectedOption1(`${params.minimum_hourly_rate}-${params.maximum_hourly_rate}`);
    } else if (params.minimum_hourly_rate) {
      setSelectedOption1(params.minimum_hourly_rate);
    }

    if (params.zip && randomLocation.length > 0) {
      const location = randomLocation.find((loc) => loc.label.includes(params.zip));
      if (location) {
        setValue("location", location);
      }
    }
  };

  useEffect(() => {
    if (randomLocation.length > 0 && workdays.length > 0 && timeSlot.length > 0) {
      populateFormFromQuery();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, randomLocation.length, workdays.length, timeSlot.length]);

  const saveFiltersToLocalStorage = (filters) => {
    const { page, ...filtersWithoutPage } = filters;
    localStorage.setItem("searchFilters", JSON.stringify(filtersWithoutPage));
  };

  const handleClearFilters = () => {
    setSearchParams({});
    reset();
    setSelectedValue1(null);
    setSelectedOption(null);
    setSelectedOption1(null);
    setSelectedOption2(null);
    setFilterType(1);

    localStorage.removeItem("searchFilters");
  };

  const handleSubmitForm = (data) => {
    const query = {
      skills: selectedValue1?.value,
      rating: selectedOption,
      day: data.daySearch?.value,
      time: data.timeSearch?.value,
      vehicle: selectedOption2 === "OwnVehicle" ? 1 : undefined,
      licence: selectedOption2 === "driverLicense" ? 1 : undefined,
      minimum_hourly_rate: selectedOption1?.split("-")[0],
      maximum_hourly_rate: selectedOption1?.split("-")[1],
      zip: data.location?.label.split(",")[1]?.trim(),
      page: "1",
      type: filterType,
    };

    const filteredQuery = {
      ...Object.fromEntries(Object.entries(query).filter(([_, v]) => v !== undefined && v !== null)),
    };

    saveFiltersToLocalStorage(filteredQuery);

    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      ...filteredQuery,
    });

    props.searchedValue(selectedValue1?.value);
  };

  const optionTaskValue = [
    { value: "Child Care", label: "Child Care" },
    { value: "Tutoring", label: "Tutoring" },
    { value: "Coaching", label: "Sports Coaching" },
    { value: "Yard Work", label: "Yard Work" },
    { value: "Moving", label: "Moving" },
    { value: "Grocery Delivery", label: "Grocery Delivery" },
    { value: "Housekeeping", label: "Housekeeping" },
    { value: "Pet Sitting", label: "Pet Sitting" },
    { value: "Elder Care", label: "Elder Care" },
    { value: "Window Cleaning", label: "Window Cleaning" },
    { value: "Other", label: "Other" },
  ];

  return (
    <div className="advanceSearch mt-5">
      <form id="advanceSearchForm" onSubmit={handleSubmit(handleSubmitForm)} className="stepForm">
        <div className="bannerFields viewStudentsField pb-4 reviewSection_dashbord row">
          <div style={{ textAlign: "left" }} className="col-lg-5 col-md-6 col-sm-6 col-12 px-0">
            <Controller
              name="location"
              control={control}
              render={({ field }) => (
                <AsyncSelect
                  {...field}
                  styles={colourStylesLocation}
                  loadOptions={(inputValue, callback) => {
                    if (!inputValue) {
                      callback(randomLocation.slice(0, 100)); // Show initial 100 options when there's no input
                      return;
                    }
                    const options = randomLocation.filter((location) => location.label.toLowerCase().includes(inputValue.toLowerCase()));
                    callback(options.slice(0, 100)); // Limit to 100 results
                  }}
                  className="cityField border-0 border-mb-fields"
                  cacheOptions
                  defaultOptions={randomLocation.slice(0, 100)} // Show initial 100 options
                  placeholder="City or zip code"
                  menuPortalTarget={document.body}
                />
              )}
            />
          </div>
          <div className="customSearchField mb-customSearchField col-lg-7 col-md-6 col-sm-6 col-12">
            <Controller
              name="taskInputValue"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  value={selectedValue1}
                  onChange={(newValue) => {
                    setSelectedValue1(newValue);
                  }}
                  styles={colourStylesLocation}
                  menuPortalTarget={document.body}
                  options={optionTaskValue}
                  className="findTasksFields border-0 border-mb-fields"
                  placeholder="Task"
                />
              )}
            />
            {errors.taskInputValue && <span className="eror">This field is required</span>}
            <button type="submit" className="taskbutton primary center">
              Find Student
            </button>
          </div>
        </div>
        <div className="advanceSearchHed align-items-center">
          <div className="pointercurser" onClick={() => setAdv_toBeShow((prev) => !prev)}>
            <h6 className="cjorta">
              <span>
                <img src="/assets/images/viewAll/advSearchIcon.svg" alt="" />
                &nbsp;&nbsp;&nbsp;
              </span>
              Advanced Search
            </h6>
          </div>
          <div className="d-inline-flex justify-content-start" style={{ gap: 5 }}>
            <span role="button" className="primary dashboardHederBtn clearFilter_btn ml-0" onClick={handleClearFilters}>
              Clear Filter
            </span>
            <img className="cjotakro" src={imageForDropDownAdv} alt="" onClick={() => setAdv_toBeShow((prev) => !prev)} />
          </div>
        </div>
        <div className="mb-4">
          {user_id != null ? (
            <div className="d-flex type-of-earch" style={{ width: "max-content" }}>
              <div>
                <span
                  role="button"
                  className={`${filterType === 1 ? "adv-search-type-active" : "adv-search-type-deactive"}`}
                  onClick={() => setFilterType(1)}
                >
                  All Students
                </span>
              </div>
              <div className="pl-2 ml-1">
                <span
                  role="button"
                  className={`${filterType === 2 ? "adv-search-type-active" : "adv-search-type-deactive"}`}
                  onClick={() => setFilterType(2)}
                >
                  My Students
                </span>
              </div>
            </div>
          ) : null}
        </div>

        {adv_toBeShow && (
          <div className="advanceSearchBody row">
            <div className="advanceBodyParts col-lg-3 col-md-4 col-sm-6 col-12">
              <div className="innerSetting">
                <h5 className="mb-2">Time</h5>
                <div className="selectDivAdv">
                  <Controller
                    name="daySearch"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="Select day"
                        className="advSelectViewAll mb-2"
                        options={optionDay}
                        styles={colourStyles}
                        isClearable
                      />
                    )}
                  />

                  <Controller
                    name="timeSearch"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="Select time"
                        className="advSelectViewAll mb-2"
                        options={optionTime}
                        styles={colourStyles}
                        isClearable
                      />
                    )}
                  />
                </div>
              </div>
            </div>

            {/* Rating */}
            <div className="advanceBodyParts advBodyPart_margin col-lg-3 col-md-4 col-sm-6 col-12">
              <div className="innerSetting">
                <h5 className="mb-2">Rating</h5>
                <div className="ratingStars">
                  <div className="pretty p-default p-round advanceBtn">
                    <input
                      name="rating"
                      id="starRating3"
                      value="3"
                      type="checkbox"
                      checked={selectedOption === "3"}
                      onChange={() => setSelectedOption(selectedOption === "3" ? null : "3")}
                    />
                    <div className="state p-success-o">
                      <label></label>
                    </div>
                  </div>
                  <div className="ms-1">
                    <img src="/assets/images/home/Star 8.svg" alt="" />
                    <img src="/assets/images/home/Star 8.svg" alt="" />
                    <img src="/assets/images/home/Star 8.svg" alt="" />
                    <img src="/assets/images/customerDashboard/dullStarForAdvSearch.svg" alt="" />
                    <img src="/assets/images/customerDashboard/dullStarForAdvSearch.svg" alt="" />
                  </div>
                </div>
                <div className="ratingStars">
                  <div className="pretty p-default p-round advanceBtn">
                    <input
                      name="rating"
                      id="starRating4"
                      value="4"
                      type="checkbox"
                      checked={selectedOption === "4"}
                      onChange={() => setSelectedOption(selectedOption === "4" ? null : "4")}
                    />
                    <div className="state p-success-o">
                      <label></label>
                    </div>
                  </div>
                  <div className="ms-1">
                    <img src="/assets/images/home/Star 8.svg" alt="" />
                    <img src="/assets/images/home/Star 8.svg" alt="" />
                    <img src="/assets/images/home/Star 8.svg" alt="" />
                    <img src="/assets/images/home/Star 8.svg" alt="" />
                    <img src="/assets/images/customerDashboard/dullStarForAdvSearch.svg" alt="" />
                  </div>
                </div>
                <div className="ratingStars align-items-center">
                  <div className="pretty p-default p-round advanceBtn">
                    <input
                      name="rating"
                      id="starRating48"
                      value="4.8"
                      type="checkbox"
                      checked={selectedOption === "4.8"}
                      onChange={() => setSelectedOption(selectedOption === "4.8" ? null : "4.8")}
                    />
                    <div className="state p-success-o">
                      <label></label>
                    </div>
                  </div>
                  <div className="ms-1">
                    <img src="/assets/images/home/Star 8.svg" alt="" />
                    <img src="/assets/images/home/Star 8.svg" alt="" />
                    <img src="/assets/images/home/Star 8.svg" alt="" />
                    <img src="/assets/images/home/Star 8.svg" alt="" />
                    <img src="/assets/images/home/Star 8.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>

            {/* Hourly Wage */}
            <div className="advanceBodyParts advBodyPart_margin col-lg-3 col-md-4 col-sm-6 col-12">
              <div className="innerSetting">
                <h5 className="mb-2">Hourly Wage</h5>
                <div className="advanceBodyDistance">
                  {[
                    { value: "20-30", display: "$20 - $30" },
                    { value: "30-40", display: "$30 - $40" },
                    { value: "40", display: "$40 and above" },
                  ].map((range) => (
                    <div className="d-flex align-items-center flex-row mt-1" key={range.value}>
                      <div className="pretty p-default p-round advanceBtn">
                        <input
                          name="hourlyWage"
                          value={range.value}
                          type="checkbox"
                          checked={selectedOption1 === range.value}
                          onChange={() => setSelectedOption1(selectedOption1 === range.value ? null : range.value)}
                        />
                        <div className="state p-success-o">
                          <label></label>
                        </div>
                      </div>
                      <div className="ms-1">
                        <label className="state p-success-o">{range.display}</label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Transportation */}
            <div className="advanceBodyParts advBodyPart_margin col-lg-3 col-md-4 col-sm-6 col-12">
              <div className="innerSetting">
                <h5 className="mb-2">Transportation</h5>
                <div className="advanceBodyDistance">
                  {[
                    { value: "driverLicense", label: "Driver's License" },
                    { value: "OwnVehicle", label: "Own Vehicle" },
                  ].map((option) => (
                    <div className="d-flex align-items-center flex-row mt-1" key={option.value}>
                      <div className="pretty p-default p-round advanceBtn">
                        <input
                          name="transportation"
                          value={option.value}
                          type="radio" // Changed to radio
                          checked={selectedOption2 === option.value}
                          onChange={() => setSelectedOption2(option.value)}
                        />
                        <div className="state p-success-o">
                          <label></label>
                        </div>
                      </div>
                      <div className="ms-1">
                        <label className="state p-success-o">{option.label}</label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default AdvacnceSearchBar;
