import React from "react";
import { Link, useNavigate } from "react-router-dom";
import ReactStars from "react-stars";
import Swal from "sweetalert2";
import { getAllPrevHiredStudents } from "../../services/prevStudents/PrevStudent";
import { deleteStudent } from "../../services/savestudents/DeleteStudent";
import { saveStudent } from "../../services/savestudents/SaveStudent";

const PreviousStudentCards = ({ setPrevHiredStudent, studentData }) => {
  let navigate = useNavigate();
  // console.log(studentData?.rating)
  let pathPage;
  if (localStorage.getItem("sparrowSignIn")) {
    pathPage = `/dashboardRoute/all-students/customer-student-profile/${studentData?.studentData?.id}`;
  }
  const getAge = (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  // defing userrole and user id
  let user_role = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.role;
  let user_id = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.id;

  // let studentAboutData = "Morbi et placerat lorem. In nec dui mattis, iaculis nulla erat et, scelerisque erat metus. Donec id euismod euismod erat. Morbi et placerat lorem. In nec dui mattis,";
  // if (studentData?.studentData?.student?.student_details?.about) {
  //     studentAboutData = studentData?.studentData?.student?.student_details?.about + ' ';

  // }

  let countPrimaryTask = 0;
  let terminateLoop = false;
  const resultForPrimaryTask =
    studentData?.studentData?.student_details?.skills?.length > 0
      ? studentData?.studentData?.student_details?.skills.split(/\s*,\s*/).map((item, index) => {
          if (!terminateLoop) {
            let displayItem = item;

            // Replace "|" with " | "
            if (displayItem.includes("|")) {
              displayItem = displayItem.replace(/\|/g, " | ");
            }

            // Adjust displayItem if it's too long
            if (displayItem.length > 28) {
              if (displayItem.toLowerCase().includes("sports coaching")) {
                displayItem = "Coaching";
              } else if (displayItem.toLowerCase().includes("tutoring")) {
                displayItem = "Tutoring";
              }
            } else {
              if (displayItem.toLowerCase().includes("sports coaching")) {
                displayItem = displayItem.replace("Sports Coaching:", "Coaching: ");
              }
            }

            // Now, calculate the length
            const itemLength = displayItem.length;

            if (countPrimaryTask + itemLength <= 28 && index < 4) {
              countPrimaryTask += itemLength;
              return <span key={index}>{displayItem.trim()}</span>;
            } else {
              terminateLoop = true;
              return (
                <Link
                  to={{
                    pathname: `/dashboardRoute/all-students/customer-student-profile/${studentData.id}`,
                  }}
                  role="button"
                >
                  <img src="/assets/images/customerDashboard/ActionsPlus_bold.svg" className="imageForMoreTask" alt="" />
                </Link>
                // break;
              );
            }
          }
        })
      : "";

  // delete student process
  const action_DeleteFav = async (std_id) => {
    if (JSON.parse(localStorage.getItem("sparrowSignIn"))?.user) {
      if (user_role === "client") {
        const resp = await deleteStudent(std_id);

        if (resp.status === 200) {
          const response = await getAllPrevHiredStudents(user_id);
          setPrevHiredStudent(response.data.students);
        } else {
          Swal.fire({
            title: resp.data.message,
            timer: 1500,
            icon: "error",
            showConfirmButton: false,
          });
        }
      } else if (user_role === "student") {
        Swal.fire({
          title: "Please log in as a client",
          timer: 1500,
          icon: "error",
          showConfirmButton: false,
        });
      }
    } else {
      setTimeout(() => {
        navigate("/signin");
      }, 0);
    }
  };

  // saving students
  const action_AddToFav = async (std_id) => {
    if (JSON.parse(localStorage.getItem("sparrowSignIn"))?.user) {
      // for getting client as a user
      if (user_role === "client") {
        const data = {
          client_id: user_id,
          student_id: std_id,
        };
        const resp = await saveStudent(data);

        if (resp.status === 200) {
          const response = await getAllPrevHiredStudents(user_id);
          setPrevHiredStudent(response.data.students);
        } else {
          Swal.fire({
            title: resp.data.message,
            timer: 1500,
            icon: "error",
            showConfirmButton: false,
          });
        }
      } else {
        Swal.fire({
          title: "Please log in as a client",
          timer: 1500,
          icon: "error",
          showConfirmButton: false,
        });
      }
    } else {
      setTimeout(() => {
        navigate("/signin");
      }, 0);
    }
  };

  // console.log(studentData?.totalOrders);
  return (
    <div className="profileBox border-mb-fields textLeft">
      <div className="profileContainer">
        <div className="profileImg">
          <img className="profileImgM imgBorder" src={studentData?.studentData?.image} alt="" />
          {/* <img
            className="badgeStdnt"
            src="/assets/images/home/badgeForStdnt.svg"
            alt=""
          /> */}
          {studentData?.totalOrders > 50 ? (
            <div className="hoverTextDiv">
              <img className="badgeStdnt" src="/assets/images/badges/goldA.svg" alt="" />
              <span className="hoverText setResonive">This student has done over 100 tasks</span>
            </div>
          ) : studentData?.totalOrders > 25 && studentData?.totalOrders <= 50 ? (
            <div className="hoverTextDiv">
              <img className="badgeStdnt" src="/assets/images/badges/silverb.svg" alt="" />
              <span className="hoverText setResonive">This student has done 25-50 tasks</span>
            </div>
          ) : (
            <div className="hoverTextDiv">
              <img className="badgeStdnt" src="/assets/images/badges/bronzec.svg" alt="" />
              <span className="hoverText setResonive">This student has done 0-25 tasks</span>
            </div>
          )}
          {studentData?.isSaved ? (
            <div className="hoverTextDiv">
              <>
                <img className="romaalimg" src="/assets/images/home/romaalG.svg" alt="" onClick={() => action_DeleteFav(studentData?.isSaved)} />
                <span className="hoverText setResonive">Remove from saved students</span>
              </>
            </div>
          ) : (
            <div className="hoverTextDiv">
              <img className="romaalimg" src="/assets/images/home/romaal.svg" alt="" onClick={() => action_AddToFav(studentData?.studentData?.id)} />
              <span className="hoverText setResonive">Add to favorite students</span>
            </div>
          )}
        </div>
        <div className="profilHed profileBoxStars mb-3" onClick={() => navigate(pathPage)}>
          <h3 className="profileBoxH3">
            {studentData?.studentData ? (
              <>
                {studentData?.studentData?.first_name && studentData?.studentData?.first_name}
                {studentData?.studentData?.last_name && " " + studentData?.studentData?.last_name.charAt(0).toUpperCase() + "."}
                {studentData?.studentData?.student_details?.dob && getAge(studentData?.studentData?.student_details?.dob) + "'"}
              </>
            ) : (
              ""
            )}
          </h3>
          <p className="profileBoxP elementCenter">
            {studentData?.studentData?.student_details?.college ? studentData?.studentData?.student_details?.college : "No college available"}
          </p>
          <div className="profileBoxStars">
            <ReactStars
              count={5}
              size={25}
              edit={false}
              color2={"#FFC700"}
              color1={"#FFC70040"}
              className="pe-none d-flex justify-content-center"
              value={studentData?.rating ? studentData?.rating : 5}
            />
          </div>
          <div className="houlyRateValueNew profileBoxStars">
            Avg. $
            {studentData?.studentData?.student_details?.minimum_hourly_rate ? studentData?.studentData?.student_details?.minimum_hourly_rate : 0}
            /hr
          </div>
        </div>
        <div className="profileContent" onClick={() => navigate(pathPage)}>
          <h4 className="profileBoxSubhed">Featured Tasks</h4>
          <div className="profileTaks flex-wrap">
            {studentData?.studentData?.student_details?.skills ? (
              resultForPrimaryTask
            ) : (
              <>
                <span>None</span>
              </>
            )}
          </div>
        </div>
        <div className="profileAbout" onClick={() => navigate(pathPage)}>
          <h4 className="profileBoxSubhed">About me</h4>

          <p>
            {
              <span className="overflowText line-clamp">
                {studentData?.studentData?.student_details?.about ? studentData?.studentData?.student_details?.about : "Not available"}
              </span>
            }
          </p>
        </div>
      </div>
      <div className="profileBtn">
        <Link
          to={`/dashboardRoute/all-students/create-tasks/${studentData?.studentData?.id}/${studentData?.studentData?.fcm_token}`}
          className="primary bookNowBtn"
        >
          Hire Again
        </Link>
      </div>
    </div>
  );
};

export default PreviousStudentCards;
